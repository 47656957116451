import MedicalHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/medical-history/MedicalHistoryTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/MedicalHistory/MedicalHistoryTemplate',
  component: MedicalHistoryTemplate
};

const createStory = props => () => ({
  components: { MedicalHistoryTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><medical-history-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  otherMedicalConditions: BOOLEAN.YES,
  medicalConditions: 'some medical conditions',
  medicalConditionsVisible: true,
  underMedications: BOOLEAN.YES,
  medicationsUsed: 'some medications used',
  medicationsUsedVisible: true
});

export const required = createStory({
  otherMedicalConditions: BOOLEAN.NO,
  underMedications: BOOLEAN.NO
});

export const medicalConditionsVisible = createStory({
  otherMedicalConditions: BOOLEAN.YES,
  medicalConditions: 'some medical conditions',
  medicalConditionsVisible: true,
  underMedications: BOOLEAN.NO
});

export const medicationsUsedVisible = createStory({
  otherMedicalConditions: BOOLEAN.NO,
  underMedications: BOOLEAN.YES,
  medicationsUsed: 'some medications used',
  medicationsUsedVisible: true
});

export const unselected = createStory({
  otherMedicalConditions: '',
  underMedications: ''
});

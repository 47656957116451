<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['otherMedicalConditions'])">
      <single-answer-question
        id="other-medical-conditions"
        title="label.otherMedicalConditions"
        :value="otherMedicalConditions"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('otherMedicalConditions', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="medicalConditionsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['medicalConditions'])"
      >
        <broad-answer-question
          id="medical-conditions"
          title="label.medicalConditions"
          :value="medicalConditions"
          @input="onFieldChange('medicalConditions', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['underMedications'])"
    >
      <single-answer-question
        id="under-medications"
        title="label.underMedications"
        :value="underMedications"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('underMedications', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="medicationsUsedVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['medicationsUsed'])"
      >
        <broad-answer-question
          id="medications-used"
          title="label.medicationsUsed"
          :value="medicationsUsed"
          @input="onFieldChange('medicationsUsed', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import BroadAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/BroadAnswerQuestion';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'MedicalHistoryTemplate',
  components: {
    FadeInTransitionWrap,
    BroadAnswerQuestion,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    otherMedicalConditions: {
      type: String,
      required: true
    },
    medicalConditions: {
      type: String,
      default: ''
    },
    medicalConditionsVisible: {
      type: Boolean,
      default: false
    },
    underMedications: {
      type: String,
      required: true
    },
    medicationsUsed: {
      type: String,
      default: ''
    },
    medicationsUsedVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>

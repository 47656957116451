<template>
  <div>
    <h2 class="u-typography-helvetica u-text u-text--s text-align-center q-mb16">
      {{ $t(title) }}
    </h2>
    <u-textarea :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import { UTextarea } from 'universkin-shared';

export default {
  name: 'BroadAnswerQuestion',
  components: { UTextarea },
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
